import Joi from 'joi'
const maxLength = 50
import passwordComplexity from 'joi-password-complexity'
const passwordMinLength = 6
const passwordComplexitySchema = {
    min: passwordMinLength,
    max: maxLength,
    lowerCase: 1,
    upperCase: 1,
    numeric: 1,
    symbol: 1,
    requirementCount: 4, // liczba warunków jednocześnie
}

const passwordSchema = Joi.object({
    newPassword1: passwordComplexity(passwordComplexitySchema, 'Złożoność hasła jest zbyt niska').messages({
        "passwordComplexity.symbol": "Brakuje jeszcze znaku specjalnego",
        "passwordComplexity.tooShort": "Hasło jest za krótkie",
        "passwordComplexity.tooLong": "Hasło jest za długie",
        "passwordComplexity.lowercase": "Brakuje jeszcze małej literki",
        "passwordComplexity.uppercase": "Brakuje jeszcze dużej literki",
        "passwordComplexity.numeric": "Brakuje jeszcze cyfry"
    }),
    newPassword2: Joi.any().valid(Joi.ref('newPassword1')).required().messages({ "any.only": "Hasła nie są identyczne" }),
    captcha: Joi.string().min(1),
    userId: Joi.number().empty().allow('')
})

export function passwordValidator(value, logic = true) {
    console.log('passwordValidator', value)
    if (!value || value == '') {
        return false
    }
    const validateResult = passwordSchema.validate(value)
    if ('error' in validateResult) {
        if (logic) {
            return true
        } else {
            return `${validateResult.error.message}`
        }
    }
    return false
}

const phoneSchema = Joi.string().label('Telefon').allow('').empty().pattern(/^[0-9 +]+$/, 'numer telefonu').min(9).max(15)
    .messages({
        'string.pattern.name': `Wymagane jest podanie cyfr telefonu`,
        'string.min': `nie może być krótsza niż {#limit}`,
        'string.max': `nie może być dłuższa niż {#limit}`,
    })
export function phoneValidator(value, logic = true) {
    // console.log('phoneValidator')
    if (!value || value == '') {
        return false
    }
    const validateResult = phoneSchema.validate(value)
    if ('error' in validateResult) {
        if (logic) {
            return true
        } else {
            return `${validateResult.error.message}`
        }
    }
    return false
}

const fornameSchema = Joi.string().label('Imię').min(3).max(maxLength).required().empty()
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })

export function fornameValidator(value) {
    const validateResult = fornameSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}
const lastnameSchema = Joi.string().label('Nazwisko').min(3).max(maxLength).required().empty()
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })

export function lastnameValidator(value) {
    const validateResult = lastnameSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const generalSchema = Joi.object({
    forname: fornameSchema,
    lastname: lastnameSchema,
    phone: phoneSchema,
    informBySMS: Joi.any(),
    informByEmail: Joi.any(),
    informByPush: Joi.any(),
})

export function doesFormHasErrors(value) {
    // console.log(value)
    const validateResult = generalSchema.validate(value)
    if (validateResult && 'error' in validateResult) {
        console.log('validation', validateResult)
        return true
    }
    return false
}


const emailSchema = Joi.string().email({ tlds: { allow: false } }).label('email').min(3).required()
    .messages({
        'any.required': `Pole jest wymagane`,
        'string.email': `Nieprawidłowy email`
    })

const resetPasswordSchema = Joi.object({
    email: emailSchema,
    captcha: Joi.string().min(1)
})

export function emailValidator(value, logic = true) {
    // console.log(value)
    const validateResult = resetPasswordSchema.validate(value)
    // if (validateResult && 'error' in validateResult) {
    //     console.log('validation', validateResult)
    //     return true
    // }
    if ('error' in validateResult) {
        if (logic) {
            return true
        } else {
            return `${validateResult.error.message}`
        }
    }
    return false
}