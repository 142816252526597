import {
    Alert, Fade,
    CircularProgress,
    Button,
    TextField,
    FormControlLabel, FormControl, FormHelperText, FormLabel,
    Grid,
    Box,
    Typography,
    Rating, Chip, RadioGroup, Radio,
    Stack, Skeleton,
    Tabs, Tab,
    Autocomplete
} from '@mui/material';
import theme from '../../theme';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import ReusableDialog from '../ReusableDialog';
import SelectService from './SelectService';
import SelectServiceDate from './SelectServiceDate'
import {
    customerNameValidator,
    phoneValidator,
    emailValidator,
    carBrandValidator,
    carModelValidator,
    plateNumberValidator,
    vinValidator,
    commentValidator,
    doesFormHasErrors
} from '../../validators/TaskEditValidator'
import { postTask, getTask, updateTask, getMyClients } from '../../services/task'
import Inspection from './Inspection'
import { deleteReservations } from '../../services/reservations';
import TabPanel from './TabPanel'
import Decision from './Decision'
import moment from 'moment';
import CompleteDialog from './CompleteDialog'

export default function TaskEdit({ user }) {
    const navigate = useNavigate();
    // statusy ładowania i zapisu
    const [isLoading, setIsLoading] = useState(true);
    const [sceletonVisible, setSceletonVisible] = useState(true);
    const [errorMessage, setErrorMessage] = useState();
    const [success, setSuccess] = useState();
    // haczyki danych
    const [id, setId] = useState();
    const [customerName, setCustomerName] = useState(user.forname + " " + user.lastname);
    const [phone, setPhone] = useState(user.phone);
    const [email, setEmail] = useState(user.login);
    const [carBrand, setCarBrand] = useState();
    const [carModel, setCarModel] = useState();
    const [carPlateNumber, setCarPlateNumber] = useState();
    const [carVIN, setCarVIN] = useState();
    const [comments, setComments] = useState();
    const [salesComments, setSalesComments] = useState();
    const [perfComments, setPerfComments] = useState();
    const [salesRating, setSalesRating] = useState();
    const [chosenServiceName, setChosenServiceName] = useState();
    const [chosenServiceValue, setChosenServiceValue] = useState();
    const [chosenServiceId, setChosenServiceId] = useState();
    const [servicePlace, setServicePlace] = useState('isGarage');
    const [serviceDate, setServiceDate] = useState();
    const [dates, setDates] = useState();
    const [status, setStatus] = useState()
    const [isValid, setIsValid] = useState(true)
    const [proposedDate, setProposedDate] = useState()
    const [proposedAsap, setProposedAsap] = useState()
    // obłsługa okienek
    const [resignationDialogOpen, setResignationDialogOpen] = React.useState(false);
    const [serviceDialogOpen, setserviceDialogOpen] = React.useState(false);
    const [serviceDateDialogOpen, setServiceDateDialogOpen] = React.useState(false);
    const [completeDialogOpen, setCompleteDialogOpen] = React.useState(false);
    // obsługa tabów i stanu okienek
    const [isFormRO, setIsFormRO] = useState(false);
    const [showSendButton, setShowSendButton] = useState(false)
    const [tabId, setTabId] = useState(0)
    const [showTabs, setShowTab] = useState(false)
    // flagi aktywności 
    const [ctrl, setCtrl] = useState([])
    // lista moich klientów
    const [clientMode, setClientMode] = useState('cPersonal')
    const [myClients, setMyClients] = useState([])
    const [myClient, setMyClient] = useState()
    const { taskId } = useParams()
    // czy wybór dać os bez grupy
    const [showChooseClientMode, setChooseClientMode] = useState(false)

    useEffect(() => {
        // console.log('TaskEdit-user', user)
        // console.log('TaskEdit-itemId', taskId)
        if (user.pia_group && user.pia_group) { // pokaż dla grup wybór
            setChooseClientMode(true)
        }

        if (taskId) {
            loadMyClients(user)
            loadTask(user, taskId)

        } else {
            // default Marka/Model dla porsze
            if (user.pia_group && user.pia_group?.name.indexOf('Porshe') > -1) {
                setCarBrand('Marka')
                setCarModel('Model')
            }
            setCtrl({
                showSend: true,
            })
            setStatus({
                strapi: 'new',
                step: 'new',
                name: 'Roboczy',
                station: 'client'
            })
            // console.log(user)
            setIsLoading(false);
        }
    }, []);

    const loadMyClients = async (user) => {
        setIsLoading(true);
        await getMyClients(user)
            .then(response => {
                // console.log(response)
                if (response.status == 'success') {
                    setMyClients(response.result)
                    // console.log(response.result)
                } else {
                    setErrorMessage('błąd pobierania danych...');
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const loadTask = async (user, taskId) => {
        setIsLoading(true);
        await getTask(user, taskId)
            .then(response => {
                // console.log(response)
                if (response.status == 'success') {
                    setId(response.result.id)
                    setCustomerName(response.result.customerName)
                    setPhone(response.result.phone)
                    setEmail(response.result.email)
                    setCarBrand(response.result.carBrand)
                    setCarModel(response.result.carModel)
                    setCarPlateNumber(response.result.carPlateNumber)
                    setCarVIN(response.result.carVIN)
                    setComments(response.result?.comments || '')
                    setSalesComments(response.result?.clientComment || '')
                    setPerfComments(response.result?.performerComment || '')
                    setSalesRating(response.result.salesRating)
                    setChosenServiceName(response.result.chosenService)
                    setChosenServiceId(response.result.chosenServiceId)
                    setServicePlace(response.result.servicePlace)
                    setServiceDate(response.result.serviceDate)
                    setDates(response.result.dates)
                    setStatus(response.result.status)
                    setChosenServiceValue(response.result.value)

                    setIsFormRO(response.result?.ctrl.isClientRO)
                    if (response.result.status == 'new') {
                        setShowSendButton(true)
                    } else {
                        setShowSendButton(false)
                    }
                    setShowTab(response.result.showTabs)
                    setCtrl(response.result?.ctrl)
                    // console.log(`proposedAsap = ${response.result?.proposedAsap}, proposedDate = ${response.result?.proposedDate}`)
                    // setProposedDate(response.result?.proposedDate ? moment(response.result?.proposedDate) : null)
                    setProposedDate(response.result?.proposedDate)
                    setProposedAsap(response.result?.proposedAsap)

                    setClientMode(response.result.clientMode)
                    const myClientLabelX = {
                        label: response.result.pia_client.customerName,
                        id: response.result.pia_client.id,
                        email: response.result.pia_client.email,
                        phone: response.result.pia_client.phone
                    }
                    setMyClient(myClientLabelX)
                    //console.log(myClientLabelX)
                } else {
                    setErrorMessage('błąd pobierania danych...');
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleSave = async e => {
        e.preventDefault();

        await handleSaveTask()
    }

    const handleSend = async e => {
        e.preventDefault();
        if (status.strapi === 'new') {
            console.log('wg mnie jest new')
            setStatus({ strapi: 'sent', ...status })
            await handleSaveTask('sent')
        } else {
            setSceletonVisible(false)
            await handleSaveTask(null,false)
            setCompleteDialogOpen(true)
            setSceletonVisible(true)
        }
    }
    
    const handleCompleteDialogOpenOnAccept = (event) => {
        setCompleteDialogOpen(false)
    }
    
    const handlCompleteDialogOpenOnReject = (event) => {
        setCompleteDialogOpen(false)
    }

    const handleSaveTask = async (newStatus, saveEndExit = true) => {

        console.log(`proposedDate ${proposedDate}`)
        const newPayload = {
            customerName: customerName,
            phone: phone,
            email: email,
            carBrand: carBrand,
            carModel: carModel,
            carPlateNumber: carPlateNumber,
            carVIN: carVIN,
            comments: comments,
            clientComment: salesComments,
            salesRating: salesRating,
            chosenServiceId: chosenServiceId,
            serviceDate: serviceDate,
            dates: dates,
            status: newStatus ? { ...status, strapi: newStatus, status: newStatus } : status,
            servicePlace: servicePlace,
            value: chosenServiceValue,
            clientMode: clientMode,
            pia_client: myClient?.id,
            proposedAsap: proposedAsap,
            proposedDate: proposedAsap ? proposedDate : (proposedDate ? proposedDate : null)
        }

        // walidacja
        let thereAreErrors = false
        if (newStatus === 'sent') {
            // console.log(newPayload)
            const validationResult = doesFormHasErrors(newPayload)
            console.log(validationResult.status)
            if (validationResult.status) {
                setIsValid(validationResult.status)
                let errorMessages = ''
                validationResult.message.map(message => {
                    console.log(message)
                    errorMessages += ' ' + message?.message
                })
                setErrorMessage(errorMessages)
                console.log(errorMessages)
                thereAreErrors = true
            }

        }

        if (!thereAreErrors) {
            // call api and save or update
            if (taskId) {
                setIsLoading(true);
                await updateTask(user, newPayload, taskId)
                    .then(response => {
                        if (response.status !== 'success') {
                            setErrorMessage(response.message)
                            setSuccess(false)
                            throw new Error(response.message);
                        } else {
                            // reload list
                            if (saveEndExit) {
                                navigate("/")
                            }
                        }
                    })
                    .catch(error => {
                        setErrorMessage(error.message);
                        setSuccess(false)
                    })
                    .finally(() => {
                        setIsLoading(false);

                    });
            } else {
                setIsLoading(true);
                await postTask(user, newPayload)
                    .then(response => {
                        if (response.status !== 'success') {
                            setErrorMessage(response.message)
                            setSuccess(false)
                            throw new Error(response.message);
                        } else {
                            // reload list
                            if (saveEndExit) {
                                navigate("/")
                            }
                        }
                    })
                    .catch(error => {
                        setErrorMessage(error.message);
                        setSuccess(false)
                    })
                    .finally(() => {
                        setIsLoading(false);

                    });
            }
        }
        return
    }

    const handleChangeServicePlace = (event) => {
        setServicePlace(event.target.value);
    };

    const handleChangeClientSelect = (event) => {
        setClientMode(event.target.value);
        switch (event.target.value) {
            case 'cSelect':
                loadMyClients(user)
                if (myClient?.id) {
                    setCustomerName(myClient.label)
                    setPhone(myClient.phone)
                    setEmail(myClient.email)
                }
                break
            case 'cPersonal':
                setCustomerName(user.forname + " " + user.lastname)
                setPhone(user.phone)
                setEmail(user.login)
                setMyClient(null)
                break
            case 'cNew':
                setCustomerName(myClient?.label)
                setPhone(myClient?.phone)
                setEmail(myClient?.email)
                setMyClient(null)
                break
        }
        // if (event.target.value == 'cSelect') {
        //     loadMyClients(user)
        // }
    };

    const handleChangeMyClient = (event, newValue) => {
        // setMyClient(event.target.outerText)
        setMyClient(newValue)
        setCustomerName(newValue?.label)
        setPhone(newValue?.phone)
        setEmail(newValue?.email)
        //console.log(newValue)
    };

    // okienko - potwierdzacz rezygnacji
    const handleResignationDialogOpen = (event) => {
        setResignationDialogOpen(true)
    };
    const handleResignationDialogOnAccept = async (event) => {
        setResignationDialogOpen(false)
        setStatus({ step: 'resignation', name: 'Rezygnacja', ...status })
        setServiceDate(null)
        setDates(null)
        await handleSaveTask('resignation')
    };
    const handleResignationDialogOnReject = (event) => {
        setResignationDialogOpen(false)
    };

    // okienko - wybór usługi
    const handleServiceDialogOpen = (event) => {
        setserviceDialogOpen(true)
    };
    const handleServiceDialogOnAccept = (newServiceId, newServiceName, newServiceValue) => {
        // console.log(newServiceId)
        setserviceDialogOpen(false)
        setChosenServiceId(newServiceId)
        setChosenServiceName(newServiceName)
        setChosenServiceValue(newServiceValue)
        // console.log(newServiceId, newServiceName, newServiceValue)
    };
    const handleServiceDialogOnReject = (event) => {
        setserviceDialogOpen(false)
    };

    // okienko - wybór terminu usługi
    const handleServiceDateDialogOpen = (event) => {
        setServiceDateDialogOpen(true)
    };
    const handleServiceDateDialogOnAccept = (type, proposedDate) => {
        // setServiceDate(reservationResult)
        console.log(proposedDate)
        if (type == 'tAsap') {
            setDates('Jak najszybciej')
            setProposedDate(null)
        } else {
            setDates(proposedDate.format('YYYY-MM-DD'))
            setProposedDate(proposedDate.format('YYYY-MM-DD'))
        }
        setProposedAsap(type == 'tAsap')
        setServiceDateDialogOpen(false)
    };
    const handleServiceDateDialogOnReject = (event) => {
        setServiceDateDialogOpen(false)
    };

    // obsługa anulowania terminu usługi
    const handleServiceDateRemove = async () => {
        setProposedAsap(null)
        setProposedDate(null)
        setDates(null)
        setStatus({ step: 'new', ...status })
    }

    // obsługa tabów
    const handleTabChange = (event, tabIndex) => {
        // console.log(`handleTabChange`)
        setTabId(tabIndex)
    }

    const form = () => {
        if (showTabs && false) {
            return (
                <Box >
                    <Tabs
                        orientation="horizontal"
                        variant="standard"
                        value={tabId}
                        onChange={handleTabChange}
                        aria-label="Vertical tabs example"
                        sx={{ display: "grid", justifyContent: 'center', borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Zamówienie" id={`vertical-tab-1`} sx={{ mx: 4 }} />
                        {/* <Tab label="Inspekcja" id={`vertical-tab-2`} sx={{ mx: 4 }} /> */}
                        <Tab label="Zakończenie" id={`vertical-tab-3`} sx={{ mx: 4 }} />
                    </Tabs>
                    <Box sx={{ display: "grid", justifyContent: 'center' }}>
                        <TabPanel value={tabId} index={0}>
                            {taskForm()}
                        </TabPanel >
                        {/* <TabPanel value={tabId} index={1}>
                            <Inspection
                                user={user}
                                ctrl={ctrl}
                                isRO={inspectionIsRO || false}
                            />
                        </TabPanel > */}
                        <TabPanel value={tabId} index={1}>
                            <Decision
                                user={user}
                            />
                        </TabPanel >
                    </Box>
                </Box>
            );
        } else { // status < inspection1
            return (
                <Box sx={{ display: "grid", justifyContent: 'center' }}>
                    {taskForm()}
                </Box>
            )
        }
    }

    const taskForm = () => {
        return (
            <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', justifyContent: 'center', alignContent: 'flex-start' }}>

                {/* // pasek tytułowy i dodawanie */}
                <Grid item container sx={{ maxWidth: "960px", mb: theme.spacing(1), width: '70%', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", flexGrow: 1 }} >
                        <Typography variant="subtitle2">
                            {taskId ? (isFormRO ? "Podgląd tematu nr " : "Edycja tematu nr ") + id : " Dodawanie nowego tematu "}
                        </Typography>
                    </Box>
                </Grid>

                {/* formularz */}
                <Grid item container sx={{ width: '70%', alignContent: 'flex-start' }} >
                    <Grid item container sx={{ justifyContent: 'space-between', mb: 0.7 }} >
                        <FormControl>
                            <FormLabel id="my-service-text">Wskaż zamawiającego</FormLabel >

                            <RadioGroup
                                row
                                aria-labelledby="service-place-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={handleChangeClientSelect}
                                value={clientMode}
                            >
                                <FormControlLabel disabled={isFormRO} value={"cPersonal"} control={<Radio />} label="Osobiście" />
                                {showChooseClientMode ? (<FormControlLabel disabled={isFormRO} value={"cSelect"} control={<Radio />} label="Wybieram" />) : ''}
                                <FormControlLabel disabled={isFormRO} value={"cNew"} control={<Radio />} label="Dodaję" />
                            </RadioGroup>
                        </FormControl>
                        {clientMode === "cSelect" ?
                            myClients.length > 0 ? (
                                <Autocomplete
                                    required
                                    disablePortal
                                    id="combo-box-client"
                                    onChange={handleChangeMyClient}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    options={myClients}
                                    value={myClient || null}
                                    sx={{ mr: 1, minWidth: "42%" }}

                                    renderInput={(params) => <TextField {...params} label="Wybierz z listy"
                                        error={customerNameValidator(myClient?.label)}
                                        helperText={customerNameValidator(myClient?.label)}
                                    />}
                                />
                            ) : `brak zdefiniowanych zlecających z Twojej grupy, wybierz opcję "Dodaje" lub zgłoś do Soft99`
                            : ''
                        }
                    </Grid>
                    {clientMode === "cNew" ? (
                        <Grid item container sx={{ justifyContent: 'space-between' }} >
                            <TextField
                                sx={{ mr: 1, minWidth: "32%" }}
                                margin="dense"
                                required
                                inputProps={{ "data-testid": "customerName" }}
                                id="customerName"
                                label="Zlecający"
                                placeholder='np. Jan Kowalski'
                                name="customerName"
                                autoFocus
                                disabled={clientMode !== 'cNew' || isFormRO}
                                value={customerName || ''}
                                error={customerNameValidator(customerName)}
                                helperText={customerNameValidator(customerName)}
                                onChange={(e) => { setCustomerName(e.target.value) }}
                            />
                            <TextField
                                sx={{ mr: 1, minWidth: "30%" }}
                                margin="dense"
                                autoComplete="tel"
                                inputProps={{ "data-testid": "phone" }}
                                required
                                name="phone"
                                label="Telefon"
                                placeholder='np. 606 101 202'
                                id="phone"
                                disabled={clientMode !== 'cNew' || isFormRO}
                                value={phone || ''}
                                error={phoneValidator(phone)}
                                helperText={phoneValidator(phone)}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                            <TextField
                                sx={{ mr: 1, minWidth: "32%" }}
                                margin="dense"
                                autoComplete="email"
                                inputProps={{ "data-testid": "email" }}
                                required
                                name="email"
                                label="Email"
                                placeholder='np. jankowal@vip.pl'
                                id="email"
                                disabled={clientMode !== 'cNew' || isFormRO}
                                value={email || ''}
                                error={emailValidator(email)}
                                helperText={emailValidator(email)}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>) : ''}

                    <Grid item container sx={{ justifyContent: 'space-between' }} >
                        <TextField
                            sx={{ mr: 1, minWidth: "48%" }}
                            margin="dense"
                            required
                            inputProps={{ "data-testid": "carBrand" }}
                            id="carBrand"
                            label="Marka"
                            placeholder='np. Porshe'
                            name="carBrand"
                            disabled={isFormRO}
                            value={carBrand || ''}
                            error={carBrandValidator(carBrand)}
                            helperText={carBrandValidator(carBrand)}
                            onChange={(e) => setCarBrand(e.target.value)}
                        />
                        <TextField
                            sx={{ mr: 1, minWidth: "48%" }}
                            margin="dense"
                            inputProps={{ "data-testid": "carModel" }}
                            required
                            name="carModel"
                            label="Model"
                            placeholder='np. 911 Carrera T'
                            id="carModel"
                            disabled={isFormRO}
                            value={carModel || ''}
                            error={carModelValidator(carModel)}
                            helperText={carModelValidator(carModel)}
                            onChange={(e) => setCarModel(e.target.value)}
                        />
                    </Grid>

                    <Grid item container sx={{ justifyContent: 'space-between' }} >
                        <TextField
                            sx={{ mr: 1, minWidth: "48%" }}
                            margin="dense"
                            inputProps={{ "data-testid": "carPlateNumber" }}
                            name="carPlateNumber"
                            label="Nr rejestracyjny"
                            placeholder='np. PO 123XD'
                            id="carPlateNumber"
                            disabled={isFormRO}
                            value={carPlateNumber || ''}
                            error={plateNumberValidator(carPlateNumber)}
                            helperText={plateNumberValidator(carPlateNumber)}
                            onChange={(e) => setCarPlateNumber(e.target.value)}
                        />
                        <TextField
                            sx={{ mr: 1, minWidth: "48%" }}
                            margin="dense"
                            inputProps={{ "data-testid": "vin" }}
                            // required
                            name="VIN"
                            label="VIN"
                            placeholder='np. 4Y1SL65848Z411439'
                            id="carPlateNumber"
                            disabled={isFormRO}
                            value={carVIN || ''}
                            error={vinValidator(carVIN)}
                            helperText={vinValidator(carVIN)}
                            onChange={(e) => setCarVIN(e.target.value)}
                        />
                    </Grid>

                    <Grid item container sx={{ justifyContent: 'space-between' }} >
                        <TextField
                            sx={{ mr: 1 }}
                            margin="dense"
                            fullWidth
                            inputProps={{ "data-testid": "comments" }}
                            id="comments"
                            label="Uwagi"
                            name="comments"
                            multiline
                            rows={2}
                            disabled={isFormRO}
                            value={comments || ''}
                            error={commentValidator(comments)}
                            helperText={commentValidator(comments)}
                            onChange={(e) => setComments(e.target.value)}
                        />
                    </Grid>
                    {status?.strapi !== 'new' ? (
                        <Grid item container sx={{ justifyContent: 'space-between' }} >
                            <TextField
                                margin="dense"
                                sx={{ mr: 1, minWidth: "48%" }}
                                inputProps={{ "data-testid": "salesComments" }}
                                id="salesComments"
                                label="Komentarz zlecającego"
                                name="salesComments"
                                multiline
                                rows={2}
                                disabled={status?.station !== 'client'}
                                value={salesComments || ''}
                                error={commentValidator(salesComments)}
                                helperText={commentValidator(salesComments)}
                                onChange={(e) => setSalesComments(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                sx={{ mr: 1, minWidth: "48%" }}
                                inputProps={{ "data-testid": "perfComments" }}
                                id="perfComments"
                                label="Komentarz wykonawcy"
                                name="salesComments"
                                multiline
                                rows={2}
                                disabled={true}
                                value={perfComments || ''}
                            />
                        </Grid>
                    ) : ''}
                    {false && (
                        <Grid item container sx={{ justifyContent: 'space-between', minWidth: "100%" }} >
                            <Rating
                                sx={{ mt: 2, mb: 2 }}
                                name="salesRating"
                                // precision={0.5}
                                disabled={isFormRO}
                                value={salesRating | 0}
                                onChange={(event, newValue) => {
                                    setSalesRating(newValue);
                                }}
                            />
                        </Grid>
                    )}

                    <Grid item container sx={{ justifyContent: 'space-between', mt: 1, mb: 1, minWidth: "100%" }} >
                        <Box sx={{ display: "flex", alignItems: "baseline" }} >
                            <Typography variant="subtitle2">
                                {chosenServiceName ? "Usługa: " : "Wybierz usługę"}
                            </Typography>
                            {
                                chosenServiceName ? (<Chip label={chosenServiceName} variant="outlined" color="success" sx={{ margin: theme.spacing(0.2) }} />) : ''
                            }
                            <Typography variant="subtitle2" sx={{ ml: 1 }}>
                                {chosenServiceName ? "Wartość: " : ""}
                            </Typography>
                            {
                                chosenServiceName ? (<Chip label={chosenServiceValue + "zł"} variant="outlined" color="success" sx={{ margin: theme.spacing(0.2) }} />) : ''
                            }
                            <Button
                                variant="outlined"
                                color="success"
                                sx={{ m: 1, minWidth: "30%" }}
                                disabled={isFormRO || !carModel}
                                onClick={handleServiceDialogOpen}
                            >
                                {chosenServiceName ? "Zmień usługę" : "Wybierz usługę"}
                            </Button>
                            {carModel ? (
                                <SelectService
                                    user={user}
                                    defaultSelectedService={chosenServiceId}
                                    isOpen={serviceDialogOpen}
                                    handleAcceptService={handleServiceDialogOnAccept}
                                    handleRejectService={handleServiceDialogOnReject}
                                    carModel={carModel == 'Model' ? "" : carModel}
                                />) : ''}
                        </Box>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="service-place-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={handleChangeServicePlace}
                                value={servicePlace}
                            >
                                <FormControlLabel disabled={isFormRO} value={"isGarage"} control={<Radio />} label="Usługa w Soft99 Garage" />
                                <FormControlLabel disabled={isFormRO} value={"isMobile"} control={<Radio />} label="Usługa mobilna" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {chosenServiceName ? (
                        <Grid item container sx={{ justifyContent: 'space-between', mt: 1, mb: 1, minWidth: "100%", alignItems: "baseline" }}>
                            {/* <Box sx={{ display: "flex", alignItems: "baseline" }} > */}
                            <Typography variant="subtitle2">
                                Termin usługi:
                            </Typography>
                            {
                                dates ? (
                                    <Chip label={dates} variant="outlined" color="success" sx={{ margin: theme.spacing(0.2) }} />
                                ) : ''
                            }

                            <Button
                                variant="outlined"
                                color="success"
                                disabled={isFormRO || !carBrand || !carModel || !carVIN}
                                onClick={handleServiceDateDialogOpen}
                            >
                                {dates ? "Zmień termin" : "Wybierz termin"}
                            </Button>

                            {dates ? (
                                <Button
                                    variant="outlined"
                                    color="warning"
                                    disabled={isFormRO}
                                    onClick={handleServiceDateRemove}
                                >
                                    Usuń termin
                                </Button>
                            ) : ''
                            }
                            <SelectServiceDate
                                serviceDate={serviceDate}
                                customerName={customerName}
                                phone={phone}
                                email={email}
                                carBrand={carBrand}
                                carModel={carModel}
                                carPlateNumber={carPlateNumber}
                                carVIN={carVIN}
                                user={user}
                                service={chosenServiceId}
                                defaultSelectedServiceTerm={serviceDate}
                                isOpen={serviceDateDialogOpen}
                                handleAcceptService={handleServiceDateDialogOnAccept}
                                handleRejectService={handleServiceDateDialogOnReject}
                                proposedAsapX={proposedAsap}
                                proposedDateX={proposedDate}
                            />
                            {/* </Box> */}
                        </Grid>
                    ) : ''}

                    <Grid item container sx={{ justifyContent: 'space-between', mt: 1, mb: 1, minWidth: "100%" }}>
                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                            <Typography variant="subtitle2">
                                Status: {status ? status.name : "-"}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item container sx={{ mb: 2 }}> </Grid>

                    <Grid item container sx={{ justifyContent: 'space-between', minWidth: "90%" }} >
                        <Box sx={{ display: "flex", alignItems: "baseline" }}>
                            {status != 'Rezygnacja' ? (
                                <Button disabled={!ctrl.resignation} onClick={handleResignationDialogOpen} variant="outlined" sx={{ mr: 1 }}>
                                    Rezygnacja
                                </Button>
                            ) : ''}
                            <ReusableDialog
                                title={'Potwierdź rezygnację z tematu'}
                                subtitle={'Czy rzeczywiście chesz zamknąć temat usługi dla ' + customerName + '?'}
                                children={'Jeżeli zrezygnujesz z tematu, zmieni się jego status na "Rezygnacja". Ponowna zmiania nie będzie możliwa. Będziesz mogł dodać kolejny temat'}
                                rejectButton={"Anuluj"}
                                acceptButton={"Rezygnuję z tematu"}
                                isOpen={resignationDialogOpen}
                                handleAccept={handleResignationDialogOnAccept}
                                handleReject={handleResignationDialogOnReject}
                            />
                        </Box>
                        <Box>
                            <Button component={Link} to="/" variant="outlined" sx={{ mr: 1 }}>
                                Anuluj
                            </Button>
                            <Button
                                disabled={isFormRO}
                                onClick={handleSave}
                                type="submit"
                                variant="contained"
                                sx={{ mr: 1 }}>
                                Zapisz
                            </Button>
                            {ctrl.showSend &&
                                <Button
                                    disabled={status?.station !== 'client'}
                                    onClick={handleSend}
                                    type="submit"
                                    variant="contained"
                                    sx={{ mr: 1 }}>
                                    Wyślij
                                </Button>
                            }
                            {isLoading && <CircularProgress />}
                            <CompleteDialog
                                user={user}
                                isOpen={completeDialogOpen}
                                handleAccept={handleCompleteDialogOpenOnAccept}
                                handleReject={handlCompleteDialogOpenOnReject}
                            />
                            <Fade
                                in={!isValid} //Write the needed condition here to make it appear
                                timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                                addEndListener={() => {
                                    setTimeout(() => {
                                        setIsValid(true)
                                    }, 2000);
                                }}
                            >
                                <Alert severity="error" variant="standard" className="alert">
                                    Występują błędy walidacji:
                                </Alert>
                            </Fade>
                        </Box>
                        <Box>
                            {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
                            {success && <div><Alert severity="success">{success}</Alert></div>}
                        </Box>

                    </Grid>

                </Grid>

            </Grid>

        )
    }

    const sceleton = () => {
        return (
            <Grid container spacing={1} sx={{ justifyContent: 'center', alignContent: 'flex-start' }}>
                <Stack spacing={1} sx={{ m: 5 }}>

                    <CircularProgress sx={{ ml: 2 }} />

                </Stack>
            </Grid>
        )
    }

    return (
        isLoading ? sceleton() : form()
    );

}

