
// wymagana struktura taskItemu
/**
 {
    pageCount: 1,
    pageSize: 5,
    tasks: [{
        id: "440",
        status: "Negocjacje",
        dates: "2023-04-12 08:30 - 2023-04-13 08:45",
        firstName: "Adam",
        surname: "Nowak",
        carName: "Porshe ADTA",
        comments: "polerowanie za lusterkami i felgi",
        chosenService: "Pakiet 1",
        updatedDate: "2023-04-01 12:34:32",
        progress: 3
    },
    ...]
 */
export async function getMyClients(user, pageNumber, pageSize) {
    const url = process.env.REACT_APP_API_URL + '/myclient'
    // console.log(`loadTasks: ${url}`)
    // console.log(user.token)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function getTasks(user, pageNumber, pageSize) {
    const url = process.env.REACT_APP_API_URL + '/tasksForSales'
    // console.log(`loadTasks: ${url}`)
    // console.log(user.token)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function updateTask(user, payload, taskId) {
    const url = process.env.REACT_APP_API_URL + '/tasks/' + taskId
    // console.log(`postTask: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(payload)
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function postTask(user, payload) {
    const url = process.env.REACT_APP_API_URL + '/tasks'
    // console.log(`postTask: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(payload)
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function getTask(user, taskId) {
    const url = process.env.REACT_APP_API_URL + '/tasks/' + taskId
    // console.log(`loadTasks: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function getTaskDecision(user, taskId) {
    const url = process.env.REACT_APP_API_URL + '/tasks/' + taskId + '/decision'
    // console.log(`loadTasks: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}
export async function postTaskDecision(user, taskId, payload) {
    const url = process.env.REACT_APP_API_URL + '/tasks/' + taskId + '/decision'
    // console.log(`loadTasks: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(payload)
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}
