
import {
  Alert,
  CircularProgress,
  Button,
  TextField,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
} from '@mui/material';

import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

import Image from '../assets/soft99-garage.png';
import Logo from '../assets/logo_garage.svg';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, } from "react-router-dom";
import { loginUser } from '../services/login';

export default function Login({ setToken, setUser }) {

  const [login, setLogin] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();

  const navigate = useNavigate();
  const recaptchaRef = React.createRef();

  const onChange = async e => {

  }

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true)
    setErrorMessage(null)

    const captchaToken = await recaptchaRef.current.executeAsync()

    const credentials = { login: login, password: password, captcha: captchaToken }
    const result = await loginUser(credentials)
    setIsLoading(false)

    switch (result.status) {
      case 'error':
      case 'credential':
        setErrorMessage(result.message)
        break;
      default:
        const x = result.response
        // console.log(`x`,x)
        setToken(x.token);

        sessionStorage.setItem('token', x.token);

        setUser(x.user);
        setSuccess('Pomyślnie zalogowałeś sie do serwisu')
        navigate("/")
        break;
    }
  }


  return (
    <Grid container spacing={1} sx={{ width: "100vw", height: "100vh" }}>
      <Grid item xs={12} sm={8} md={8} sx={{ height: '-webkit-fill-available' }}>
        <img src={Image} alt="budynek soft99 Garage nocą" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item container sx={{ justifyContent: 'center', alignItems: 'center' }} xs={12} sm={4} md={4} >
        <Box sx={{ width: '60%' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Logo} alt="logo soft99 Garage" width={'90%'} />
          </Box>
          <Typography component="h1" variant="overline">
            PIA
          </Typography>
          <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
            Zaloguj się
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={login || ''}
              onChange={(e) => setLogin(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Hasło"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />
            {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Zapamiętaj mnie"
                /> */}
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Zaloguj
            </Button>
            {isLoading && <CircularProgress sx={{ ml: 2 }} />}
            {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
            {success && <div><Alert severity="success">{success}</Alert></div>}
            <Grid container>
              <Grid item xs>
                <Link href="/lostpassword" variant="body2">
                  Zapomniałeś hasła?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Nie masz konta? Rejestracja"}
                </Link>
              </Grid>
            </Grid>
            <Grid>
              <ReCAPTCHA
                className="recaptcha"
                size="invisible"
                data-theme="dark"
                render="explicit"
                sitekey={SITE_KEY}
                ref={recaptchaRef}
                onChange={onChange}
              />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}