
import {
    Alert,
    CircularProgress,
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import theme from '../../theme';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import TaskListCard from './TaskListCard';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import { getTasks } from '../../services/task'

export default function Tasks({ user }) {
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [pageCount, setPageCount] = useState(0);
    const [tasks, setTasks] = useState([]);

    const [refreshInterval, setRefreshInterval] = useState(15000);
    const [onStart, setOnStart] = useState(true)

    const navigate = useNavigate();

    useEffect(() => {
        if (onStart) {
            setOnStart(false)
            handleRefresh()
        }
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(handleRefresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refreshInterval]);

    const handleRefresh = () => {
        loadTasks(user, pageNumber, pageSize)
    }

    const handleAdd = () => {
        setAnchorEl(null)
        navigate("/addTask")
    }
    // const handlePageChange = (pageNumber) => {
    //     loadCars(pageNumber, pageSize);
    // }

    const loadTasks = async (user, pageNumber, pageSize) => {
        setIsLoading(true);
        await getTasks(user, pageNumber, pageSize)
            .then(response => {
                // console.log(response)

                if (response.status == 'success') {
                    setTasks(response.result.tasks);
                    setPageNumber(response.result.pageNumber);
                    setPageCount(response.result.pageCount)
                    setPageSize(response.result.pageSize)
                } else {
                    setErrorMessage(response.message);
                }

            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <Box sx={{ display: "grid", width: "100vw", justifyContent: 'center' }}>
            <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>
                {/* // pasek tytułowy i dodawanie */}
                <Grid item container sx={{ mb: theme.spacing(1), width: '70%', height: "5vw", justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", flexGrow: 1 }} >
                        <Typography variant="subtitle2">
                            Oto lista Twoich zadań {user.forname}
                        </Typography>
                    </Box>
                    <IconButton
                        sx={{ fontSize: 40 }}
                        aria-label="refresh tasks"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleRefresh}
                        color="white"
                    >
                        <RefreshIcon fontSize="inherit" />
                    </IconButton>

                    <IconButton
                        sx={{ fontSize: 40 }}
                        aria-label="add task"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleAdd}
                        color="white"
                    >
                        <AddCircleIcon fontSize="inherit" />
                    </IconButton>
                </Grid>

                {/* // lista */}
                <Grid item container sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
                    {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}

                    {(tasks && tasks.length > 0) ?
                        tasks.map((taskItem) => (< TaskListCard key={taskItem.id} item={taskItem} user={user} />) //item, user
                        ) :
                        <Typography variant="subtitle2">
                            {(isLoading) ? "Trwa ładowanie danych..." : "Nie masz żadnych tematów sprzedażowych. Aby dodać Twój pierwszy kliknij ikonkę z plusikiem."}
                        </Typography>
                    }
                </Grid>
                {isLoading && <CircularProgress sx={{ ml: 2 }} />}
            </Grid>
        </Box>
    );
}