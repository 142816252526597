
import {
    Alert,
    CircularProgress,
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
    ToggleButton, ToggleButtonGroup, Pagination
} from '@mui/material';
import theme from '../../theme';
import NotifyListCard from './NotifyListCard';

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getNotification } from '../../services/notify'

export default function Notifications({ user, ctrl, isRO = true }) {
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [notifications, setNotification] = useState([]);

    const [actionFilter, setActionFilter] = useState('all');

    useEffect(() => {
        loadActions(user, actionFilter, pageNumber, rowsPerPage)
    }, []);

    const handleChangePage = async (event, newPage) => {
        console.log(newPage)
        setPageNumber(newPage);
        loadActions(user, actionFilter, newPage, rowsPerPage)
    };

    const loadActions = async (user, actionFilter, pageNumber, rowsPerPage) => {
        setIsLoading(true)
        // console.log('ładowanie stacji')
        await getNotification(user, actionFilter, pageNumber, rowsPerPage)
            .then(response => {
                // console.log(response)
                if (response.status == 'success') {
                    setNotification(response.result.notifications);
                    //console.log(response.result.notifications)
                    setPageNumber(response.result.pageNumber);
                    setPageCount(response.result.pageCount)
                    setRowsPerPage(response.result.rowsPerPage)
                } else {
                    setErrorMessage(response.message);
                }
            })
            .catch(error => {
                setErrorMessage(error.message)
                setPageNumber(1);
                setPageCount(1)
                setRowsPerPage(1)
            })
            .finally(() => {
                setIsLoading(false);
                setErrorMessage(false);
            });
    }

    const refreshAction = async () => {
        loadActions(user, actionFilter, pageNumber, rowsPerPage)
    }

    // menu pinezkowe
    const handleChangeActionFilter = (event, newActionType) => {
        if (newActionType !== null) {
            setActionFilter(newActionType);
            loadActions(user, newActionType, pageNumber, rowsPerPage)
        }
    };

    const sidebar = () => {
        return (
            <Grid container spacing={1} sx={{ justifyContent: 'center', alignContent: 'flex-start' }}>
                <Grid item container sx={{ justifyContent: 'center', alignContent: 'flex-start' }} >
                    <ToggleButtonGroup
                        value={actionFilter}
                        onChange={handleChangeActionFilter}
                        exclusive
                        aria-label="actionFilter">
                        <ToggleButton value="unread" aria-label="defect" variant="filled" color="error">
                            Nieprzeczytane
                        </ToggleButton>
                        <ToggleButton value="read" aria-label="action" color="success">
                            Przeczytane
                        </ToggleButton>
                        <ToggleButton value="all" aria-label="action" color="secondary">
                            Wszystkie
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box sx={{ display: "grid", justifyContent: 'center' }}>
            <Grid item container sx={{ mb: theme.spacing(1), width: '70%', height: "5vw", justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: "flex", flexGrow: 1 }} >
                    <Typography variant="subtitle2">
                        Lista powiadomień
                    </Typography>
                </Box>
            </Grid>
            <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>
                {sidebar()}
                {/* // lista */}
                <Grid item container sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
                    {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}

                    {(notifications && notifications.length > 0) ?
                        notifications.map((notification) => (
                            <NotifyListCard
                                key={notification.id}
                                element={notification}
                                user={user}
                                refreshAction={refreshAction}
                                ctrl={ctrl}
                                isRO={isRO}
                            />
                        )
                        ) :
                        <Typography variant="subtitle2">
                            {(isLoading) ? "Trwa ładowanie danych..." : "Nie ma żadnych powiadomień"}
                        </Typography>
                    }
                </Grid>                
            </Grid>
            <Box sx={{ display: "grid", m: 1, justifyContent: 'center', alignItems: 'flex' }}>
                <Pagination
                    variant="outlined"
                    color="primary"
                    component="div"
                    size="large"
                    page={pageNumber}
                    count={pageCount}
                    onChange={handleChangePage}
                    showFirstButton showLastButton
                />
            </Box>
            {isLoading && <CircularProgress sx={{ ml: 2 }} />}
        </Box>
    );
}