import React, { useEffect, useState } from 'react';
import { Router, Routes, Route, Navigate } from "react-router-dom";

import Navbar from "./components/Navbar";
import Tasks from './components/Tasks/Tasks';
import TaskEdit from './components/Tasks/TaskEdit';
import Notifications from './components/Notify/NotifyList';
import Profile from './components/Profile';
import Password from './components/Password';
import Login from "./components/Login";
import LostPassword from './components/LostPassword';
import Register from './components/Register';
import NotFoundPage from "./components/NotFoundPage";
import { loginByToken } from './services/login';
import { Alert, CircularProgress, Grid, Stack } from '@mui/material';
import ResetPassword from './components/ResetPassword';
export default function RouteManager() {
  const [token, setToken] = useState()
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    const xToken = sessionStorage.getItem('token')
    // console.log('RouteManager', user, xToken)

    if (xToken) {
      loginByToken(xToken).then((result) => {
        // console.log(result)
        if (result?.response?.message && result?.response?.message.length > 0) {
          // error
          setToken(null)
        } else {
          const x = result.response
          // console.log(`y`,x.user)
          setUser(x.user)
          setToken(x.user.token)
        }
      }).catch(e => {
        console.error(`useEffect in RouteManager, `, e)
      }).finally(() => {
        // await new Promise(resolve => setTimeout(resolve, 300));
        setIsLoading(false)
      }
      )
    } else {
      setIsLoading(false)
    }

  }, []);

  const login = () => {
    const pathname = window.location.pathname
    // console.log(pathname)
    switch (pathname) {
      case '/register': return <Register />; break;
      case '/lostpassword': return <LostPassword />; break;
      case '/':      
        if (isLoading) {
          return sceleton()
        } else {
          return <Login setToken={setToken} setUser={setUser} setIsLoading={setIsLoading} />
        }
        break;
      default: return <ResetPassword/>;break; // tutaj zakładam że nie będzie innych takich stron jak reset hasła
    }
  }
  const sceleton = () => {
    return (
      <Grid container spacing={1} sx={{ width: "100vw", justifyContent: 'center', alignContent: 'flex-start' }}>
        <Stack spacing={1} sx={{ m: 5 }}>

          <CircularProgress sx={{ ml: 2 }} />

        </Stack>
      </Grid>
    )
  }
  const form = () => {
    return (
      <div className="layout">

        <Navbar user={user} setToken={setToken} />
        <Routes>
          <Route exact path="/" element={<Tasks user={user} />} />
          <Route exact path="/addtask" element={<TaskEdit user={user} />} />
          <Route exact path="/opentask/:taskId" element={<TaskEdit user={user} />} />
          <Route exact path="/notifications" element={<Notifications user={user} />} />
          <Route exact path="/profile" element={<Profile user={user} setUser={setUser} />} />
          <Route exact path="/password" element={<Password user={user} setUser={setUser} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

      </div>

    );
  }

  return (
    token ?
      (isLoading ? sceleton() : form())
      : (isLoading ? sceleton() : login())
  );
}
// thanks for:
// based on https://medium.com/@Learnwithjoseph/how-to-add-login-authentication-to-react-applications-a02a2c93c7ae
// nested https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/